import { createRouter, createWebHistory } from 'vue-router'
import { loaderState } from '@/states/loaderState'
import HomeView from '@/views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/details/:id',
    name: 'productDetails',
    component: () => import(/* webpackChunkName: "productDetails" */ '@/views/ProductDetailsView.vue')
  },
  {
    path: '/search',
    name: 'search',
    component: () => import(/* webpackChunkName: "searchView" */ '@/views/SearchView.vue')
  },
  {
    path: '/:pathMatch(.*)*', 
    redirect: '/',
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // Si hay una posición guardada (como al usar el botón de atrás), úsala
    if (savedPosition) {
      return savedPosition;
    }
    // Por defecto, siempre navega al tope de la página
    return { top: 0 };
  }
})

router.beforeEach((to, from, next) => {
  loaderState.isLoading = true;
  next();
});

router.afterEach((to) => {
  let delay = 150;
  if(to.name == 'productDetails') delay = 1500;
  setTimeout(() => {
    loaderState.isLoading = false;
  }, delay);
});

export default router
