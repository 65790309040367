<template>
<div class="row">
    <div class="col-md-12">
        <div :class="cols">
            <Product :product="product" :key="`product-${product.id}`" v-for="product of fProductList" />
        </div>
    </div>
</div>
</template>


<script>
import { defineComponent, computed, ref, watch } from 'vue'
import Product from '@/components/Base/Product.vue'
import productList from '@/records/products.js'

export default defineComponent({
    components:{ Product },
    props: { 
        filters: { type: Object, default: () => ({'categories': [], 'status': [], 'search': null, 'price': [0,0]}) },
        columns: { type: String, default: 4 } 
    },
    setup(props) {
        const cols = computed(() => {return `product-grid row row-cols-1 row-cols-sm-2 row-cols-md-${props.columns}`});

        const fProductList = ref(productList);

        const filterProductList = (list, filters) => {
            return list.filter(item => {
                //categories filter
                if(filters?.categories && filters.categories.length > 0){
                    if(!item.categories.some(el => filters.categories.includes(el))) return false;
                }

                //search filter
                if(filters?.search && filters.search.length>0){
                    if(!item.keys.some(el => el.trim().toLowerCase().includes( filters.search.trim().toLowerCase()))) return false; 
                }
                
                //status filter
                if(filters?.status && filters?.status.length>0){
                    if(!item.status.some(el => filters.status.includes(el))) return false; 
                }
                return true;
            })
        }
        
        watch( () => props.filters, (newValue) => { 
            console.log('filter ==>', newValue);
            fProductList.value = filterProductList(productList, newValue) 
            }, { deep: true })




        return { fProductList, cols }
    },
})
</script>
