<template>
<div>
  <BannerContainer/>
  <section class="pb-5">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="section-header d-flex flex-wrap justify-content-between my-4">
            <h2 class="section-title">Productos Destacados</h2>
            <div class="d-flex align-items-center">
              <router-link :to="{name:'search'}" class="btn btn-primary rounded-1">Ver todos</router-link>
            </div>
          </div>
        </div>
      </div>
      <ProductList columns="5"/>
    </div>
  </section>
</div>
</template>

<script>
import BannerContainer from '@/components/Base/BannerContainer.vue'
import ProductList from '@/components/Base/ProductList.vue'

export default {
  name: 'HomeView',
  components: {
    BannerContainer,
    ProductList
  }
}
</script>
