<template>
    <section class="banner-background">
        <div class="container-lg">
            <div class="row">
                <div class="col-lg-6 pt-5 mt-5">
                    <div class="row my-6">
                        <div class="col">
                            <div class="row">
                            <div class="col-auto"><p class="fs-1 fw-bold lh-sm mb-0">1k+</p></div>
                            <div class="col"><p class="text-uppercase lh-sm mb-0 text-bg-dark">Variedad de Productos</p></div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="row">
                            <div class="col-auto"><p class="fs-1 fw-bold lh-sm mb-0">1.1k+</p></div>
                            <div class="col"><p class="text-uppercase lh-sm mb-0 text-bg-dark">Clientes Felices</p></div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="row">
                            <div class="col-auto"><p class="fs-1 fw-bold lh-sm mb-0">10+</p></div>
                            <div class="col"><p class="text-uppercase lh-sm mb-0 text-bg-dark">Store Locations</p></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>                
        </div>
    </section>
</template>


<style scoped>
.banner-background{
    position: relative;
    width: 100%;
    height: 75dvh;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    text-align: center;
    overflow: hidden;
}

.banner-background::before{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('@/assets/images/banner/banner-3.webp');
    background-size: cover;
    z-index: -1;
}

</style>