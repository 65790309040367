<template>
  <div class="product-slider">
    <Swiper
        :modules="modules"
        :slides-per-view="1"
        :space-between="10"
        :loop="true"
        :pagination="{ clickable: true }"
        :autoplay="{ delay: 3200 }"
        class="swiper-container"
    >
      <SwiperSlide v-for="(banner, index) in banners" :key="index" class="swiper-slide">
        <component :is="banner" />
      </SwiperSlide>
    </Swiper>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Pagination, Autoplay } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';

import Banner1 from '@/components/Base/Banners/Banner1.vue'
import Banner2 from '@/components/Base/Banners/Banner2.vue'
import Banner3 from '@/components/Base/Banners/Banner3.vue'

export default defineComponent({
    components: { Swiper, SwiperSlide },
    setup() {
      return  { banners: [ Banner1, Banner2, Banner3], modules: [Pagination,Autoplay], }
    }
})


</script>

<style scoped>
.product-slider {
  width: 100%;
  margin: 0 auto;
}
.product-card {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.product-image {
  width: 100%;
  height: auto;
  border-radius: 10px;
}
.product-info {
  text-align: center;
  margin-top: 10px;
}
.product-name {
  font-size: 1.2em;
  font-weight: bold;
  color: #2f4f4f;
}
.product-price {
  font-size: 1em;
  color: #4caf50;
}
</style>