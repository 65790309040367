export default [
    {
        id: '1',
        name: 'antigüedades retro' ,
    },
    {
        id: '2',
        name: 'secretos ancestrales' ,
    },
    {
        id: '3',
        name: 'finca raiz' ,
    },
    {
        id: '4',
        name: 'servicios logísticos' ,
    },
    {
        id: '5',
        name: 'bolsa de empleo' ,
    },
    {
        id: '6',
        name: 'variedades y miselaneos' ,
    },
    {
        id: '7',
        name: 'servicio social de mascotas' ,
    },
    {
        id: '8',
        name: 'artesanías' ,
    },
    {
        id: '9',
        name: 'colchones, alcoba y edredones' ,
    }
]