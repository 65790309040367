<template>
<svg xmlns="http://www.w3.org/2000/svg" style="display: none;">
    <defs>
        <symbol xmlns="http://www.w3.org/2000/svg" id="delivery" viewBox="0 0 32 32">
        <path fill="currentColor"
            d="m29.92 16.61l-3-7A1 1 0 0 0 26 9h-3V7a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v17a1 1 0 0 0 1 1h2.14a4 4 0 0 0 7.72 0h6.28a4 4 0 0 0 7.72 0H29a1 1 0 0 0 1-1v-7a1 1 0 0 0-.08-.39M23 11h2.34l2.14 5H23ZM9 26a2 2 0 1 1 2-2a2 2 0 0 1-2 2m10.14-3h-6.28a4 4 0 0 0-7.72 0H4V8h17v12.56A4 4 0 0 0 19.14 23M23 26a2 2 0 1 1 2-2a2 2 0 0 1-2 2m5-3h-1.14A4 4 0 0 0 23 20v-2h5Z" />
        </symbol>

        <symbol xmlns="http://www.w3.org/2000/svg" id="check" viewBox="0 0 24 24">
        <path fill="currentColor"
          d="M18.71 7.21a1 1 0 0 0-1.42 0l-7.45 7.46l-3.13-3.14A1 1 0 1 0 5.29 13l3.84 3.84a1 1 0 0 0 1.42 0l8.16-8.16a1 1 0 0 0 0-1.47Z" />
        </symbol>

        <symbol xmlns="http://www.w3.org/2000/svg" id="calendar" viewBox="0 0 24 24">
        <path fill="currentColor"
            d="M19 4h-2V3a1 1 0 0 0-2 0v1H9V3a1 1 0 0 0-2 0v1H5a3 3 0 0 0-3 3v12a3 3 0 0 0 3 3h14a3 3 0 0 0 3-3V7a3 3 0 0 0-3-3Zm1 15a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-7h16Zm0-9H4V7a1 1 0 0 1 1-1h2v1a1 1 0 0 0 2 0V6h6v1a1 1 0 0 0 2 0V6h2a1 1 0 0 1 1 1Z" />
        </symbol>
    </defs>
</svg>
<section class="bg-dark text-light" :style="{height: '75dvh', backgroundImage: `url(${require('@/assets/images/banner/banner-2.jpg')})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }">
    <div class="container-lg">
      <div class="row justify-content-center align-items-center">
        <div class="col-lg-6 pt-5 mt-5 pb-5 mb-5">
          <div class="d-flex text-white mb-4 p-4">
            <div class="text-white mb-3">
              <svg class="text-white flex-shrink-0 me-3" width="3em" height="3em">
                <use xlink:href="#delivery"></use>
              </svg>
            </div>
            <div>
              <h5 class="text-white">Entrega Gratuita</h5>
              <p class="card-text">Entrega Gratuita a municipios de la region del Sumapaz</p>
            </div>
          </div>

          <div class="d-flex text-white mb-3 p-3">
            <div class="text-white mb-3">
              <svg class="text-white flex-shrink-0 me-3" width="3em" height="3em">
                <use xlink:href="#check"></use>
              </svg>
            </div>
            <div>
              <h5 class="text-white">100% compras seguras</h5>
              <p class="card-text">Contactas directamente con el vendedor para conocer condiciones de entrega y pago de los productos o servicios.</p>
            </div>
          </div>

          <div class="d-flex text-white mb-3 p-3">
            <div class="text-white mb-3">
              <svg class="text-white flex-shrink-0 me-3" width="3em" height="3em">
                <use xlink:href="#calendar"></use>
              </svg>
            </div>
            <div>
              <h5 class="text-white">Nuevos productos y Servicios</h5>
              <p class="card-text">Diaramente Agregamos constatemente nuevos productos para tu disposicion.</p>
            </div>
          </div>

        </div>
        <div class="col-md-6">

        </div>
      </div>
    </div>
</section>
</template>
