<template>
  <div class="app-preloader">
    <div class="app-loader"></div>
  </div>
</template>

<style>

/* HTML: <div class="loader"></div> */
.app-loader {
  width: 50px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: 
    radial-gradient(farthest-side,#b40000 94%,#0000) top/8px 8px no-repeat,
    conic-gradient(#0000 30%,#b40000);
  -webkit-mask: radial-gradient(farthest-side,#0000 calc(100% - 8px),#000 0);
  animation: l13 1s infinite linear;
}
@keyframes l13{ 
  100%{transform: rotate(1turn)}
}

.app-preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(236, 236, 236, 0.959);
  font-size: 1.5em;
  z-index: 1000;
  flex-direction: column;
}
</style>