<template>
    <Overlay 
        ref="overlayRef"
        :showClose="false"
    >
        <div class="categories-container">
            <div class="categories-header">
                <span class="title">Categorias</span>
                <button class="close-button" @click.prevent="close">&times;</button>
            </div>
            <div class="categories-list">

                <router-link 
                    v-for="category of categories" 
                    class="category-link" 
                    :to="{'name': 'search', query: {'categories': [category.id] } }"
                    @click="close" 
                    :key="category.id" 
                > 
                    {{ category.name }} 
                </router-link>

            </div>
        </div>
    </Overlay>
</template>

<script>
import Overlay from '@/components/Utils/Overlay'
import categories from '@/records/categories.js'

import { defineComponent, ref } from 'vue'

export default defineComponent({
    name: 'CategoriesList',
    components:{
        Overlay
    },
    setup(props, {expose}) {

        const overlayRef = ref(null);

        const open = () => overlayRef.value.open();
        const close = () => overlayRef.value.close();

        expose({open, close})

        return { categories, overlayRef, close }
    },
})
</script>


<style scoped>
.categories-container {
    display: flex;
    flex-direction: column;
    background: #ffffff;
    height: 100%;
    width: 25dvw;
}

.categories-header {
    display: flex;
    background-color: #E30613;
    align-items: center;
    padding: 5px 10px 5px;
}

.categories-header .close-button{
    background: none;
    border: none;
    font-size: 30px;
    cursor: pointer;
    color: #e6e6e6;
    transition: color 0.3s;
    margin-left: auto;
}

.categories-header .title{
    font-size: 20px;
    font-weight: bolder;
    color: white;
}

.categories-header .close-button:hover {
  color: #ffffff;
}


.categories-list {
    display: flex;
    flex-direction: column;
    padding: 20px;
}

.category-link{
    font-size: 15px;
    padding: 20px 15px;
    margin-right: 35px;
    text-decoration: none;
    color: #474747;
    cursor: pointer;
    text-transform: capitalize;
    font-weight: bolder;
    border-bottom: 1px solid rgb(184, 184, 184); 
}

.category-link:hover {
    background:#005CA9;
    box-shadow: 0px 3px 6px #005ca924;
    border-radius: 8px;
    color: #ffffff;
}

/* ============ mobile view ============ */
@media(max-width: 991px){
    .categories-container {
        display: flex;
        flex-direction: column;
        background: #ffffff;
        height: 100%;
        width: 80dvw;
    }

    .categories-header .title{
        font-size: 15px;
    }

    .category-link{
        font-size: 13px;
    }
}

</style>