<template>
    <section :style="{ height: '75dvh', backgroundImage: `url(${require('@/assets/images/banner/banner-1.jpg')})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }">
        <div class="container-lg">
            <div class="row">
                <div class="col-lg-6 pt-5 mt-5">
                    <h2 class="display-1 ls-1"><span class="fw-bold text-primary">Cientos</span> de productos a tu <span class="fw-bold">Alcance</span></h2>
                    <p class="fs-4">Puedes comprar o vender facilmente cualquier producto.</p>
                    <div class="d-flex gap-3">
                    <a href="#" class="btn btn-primary text-uppercase fs-6 rounded-pill px-4 py-3 mt-3">Comprar</a>
                    <a href="#" class="btn btn-dark text-uppercase fs-6 rounded-pill px-4 py-3 mt-3">Vender</a>
                    </div>
                </div>
            </div>                
        </div>
    </section>
</template>
